import { ThermalStateProvider } from "./ThermalStateProvider";
import { ExtendedThermalCalculation } from "./ExtendedThermalCalculation";

export const ExtendedThermalCalculationContainer = () => {
  return (
    <ThermalStateProvider>
      <ExtendedThermalCalculation />
    </ThermalStateProvider>
  );
};
