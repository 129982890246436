import styled from "styled-components";
import { Separator, TableCorrectionsRow } from "./ExtendedThermalCalculation";
import { SyntheticEvent, useMemo } from "react";
import { useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { useExtendedThermalCalculation } from "./ventilatedWallExtendedCalculation.hook";

const TableBody = styled.div`
`;

export const CorrectionsTableBody = () => {
  const { t } = useTranslation() as any;
  const { performance, corrections, setCorrections } = useThermalState();
  const { formatCalc } = useExtendedThermalCalculation()

  const convectionCorrectionOptions = useMemo(() => {
    return [
      { value: corrections.convection.type, label: t(`material.${corrections.convection.type}`) },
      { value: "NO_CONVECTION", label: t("material.NO_CONVECTION") },
      { value: "WEAK_CONVECTION", label: t("material.WEAK_CONVECTION") },
      { value: "STRONG_CONVECTION", label: t("material.STRONG_CONVECTION") },
    ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value)).sort((a, b) => a.label.localeCompare(b.label));
  }, [corrections.convection, t]);

  const fixationCorrectionOptions = useMemo(() => {
    return [
      { value: corrections.fixation.type, label: t(`material.${corrections.fixation.type}`) },
      { value: "GALVANIZED_STEEL_FIXATION", label: t("material.GALVANIZED_STEEL_FIXATION") },
      { value: "RVS_FIXATION", label: t("material.RVS_FIXATION") },
    ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value)).sort((a, b) => a.label.localeCompare(b.label));
  }, [corrections.fixation, t]);

  const handleEditableOptionChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    const input = event.target as HTMLInputElement
    const type = input.dataset.type!;

    if (input.value) {

      let values = {}
      switch (input.value) {
        case 'NO_CONVECTION':
          values = { UValue: 0.00 }
          break;
        case 'WEAK_CONVECTION':
          values = { UValue: 0.01 }
          break;
        case 'STRONG_CONVECTION':
          values = { UValue: 0.04 }
          break;
        case 'GALVANIZED_STEEL_FIXATION':
          values = { lambda: 50, diameter: corrections.fixation.diameter, count: corrections.fixation.count }
          break;
        case 'RVS_FIXATION':
          values = { lambda: 17, diameter: corrections.fixation.diameter, count: corrections.fixation.count }
          break;
        default:
          values = {}
          break;
      }

      setCorrections({
        ...corrections,
        [type]: { type: input.value, ...values },
      });
    }
  }

  const handleEditableFixationCountChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement
    const type = input.dataset.type!
    let count = input.value
    let validCount = true
    if (!count || isNaN(parseFloat(count))) validCount = false

    if (type && validCount) {
      setCorrections({
        ...corrections,
        [type]: { ...corrections[type], count },
      })
    }
  }

  const handleEditableFixationDiameterChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;
    const type = input.dataset.type!;
    let diameter = input.value
    let validDiameter = true;
    if (!diameter || isNaN(parseFloat(diameter))) validDiameter = false

    if (type && validDiameter) {
      setCorrections({
        ...corrections,
        [type]: { ...corrections[type], diameter },
      })
    }
  }

  return (
    <TableBody>

      {/* Convection */}
      <TableCorrectionsRow
        classes="-corrections"
        type="convection"
        title={t("extendedThermalCalculation.correction.convection").toUpperCase()}
        text={t(`material.${corrections.convection.type}`)}
        count={undefined}
        diameter={undefined}
        lambdaValue={undefined}
        insulationValue={formatCalc(undefined)}
        UValue={formatCalc(performance.convectionUValue)}

        options={convectionCorrectionOptions}
        onOptionChange={handleEditableOptionChange}
      />

      <Separator margin="0" />

      {/* Fixation */}
      <TableCorrectionsRow
        classes="-corrections -even-row"
        type="fixation"
        title={t("extendedThermalCalculation.correction.fixation").toUpperCase()}
        text={t(`material.${corrections.fixation.type}`)}
        count={corrections.fixation.count}
        diameter={corrections.fixation.diameter}
        lambdaValue={formatCalc(corrections.fixation.lambda)}
        insulationValue={formatCalc(undefined)}
        UValue={formatCalc(performance.fixationUValue)}

        editableFixationCount={true}
        onEditableFixationCountChange={handleEditableFixationCountChange}
        editableFixationDiameter={true}
        onEditableFixationDiameterChange={handleEditableFixationDiameterChange}

        options={fixationCorrectionOptions}
        onOptionChange={handleEditableOptionChange}
      />
    </TableBody>)
}
