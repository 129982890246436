import React from "react";
import styled from "styled-components";
import { useModal } from "../../store/modal/hooks";
import { ModalDisplayTypes } from "../../store/modal/store";
import { styles } from "../../styles";
import { CountryAndLanguage } from "./CountryAndLanguage";
import { ClimateClass } from "./ClimateClass";
import { RequestAdvice } from "./RequestAdvice";
import { RequestAdviceSuccess } from "./RequestAdviceSuccess";
import { Support } from "./Support";
import { SupportSuccess } from "./SupportSuccess";
import { DetailInfo } from "./DetailInfo";

// FLAT ROOF
import { ThermalCalculation as FlatRoofThermalCalculation } from "../sidebar/flat-roof";
import { Download as FlatRoofDownload } from "../sidebar/flat-roof";
import { DownloadMail as FlatRoofDownloadMail } from "../sidebar/flat-roof";
import { DownloadSuccess as FlatRoofDownloadSuccess } from "../sidebar/flat-roof";

// CAVITY WALL
import { ThermalCalculation as CavityWallThermalCalculation } from "../sidebar/cavity-wall";
import { Download as CavityWallDownload } from "../sidebar/cavity-wall";
import { DownloadMail as CavityWallDownloadMail } from "../sidebar/cavity-wall";
import { DownloadSuccess as CavityWallDownloadSuccess } from "../sidebar/cavity-wall";

// SLOPED ROOF
import { ThermalCalculation as SlopedRoofThermalCalculation } from "../sidebar/sloped-roof";
import { Download as SlopedRoofDownload } from "../sidebar/sloped-roof";
import { DownloadMail as SlopedRoofDownloadMail } from "../sidebar/sloped-roof";
import { DownloadSuccess as SlopedRoofDownloadSuccess } from "../sidebar/sloped-roof";

// VENTILATED WALL
import { ThermalCalculation as VentilatedWallThermalCalculation } from "../sidebar/ventilated-wall";
import { ExtendedThermalCalculationContainer as VentilatedWallExtendedThermalCalculation } from "../sidebar/ventilated-wall";
import { Download as VentilatedWallDownload } from "../sidebar/ventilated-wall";
import { DownloadMail as VentilatedWallDownloadMail } from "../sidebar/ventilated-wall";
import { DownloadSuccess as VentilatedWallDownloadSuccess } from "../sidebar/ventilated-wall";

// FLOOR
import { ThermalCalculation as FloorThermalCalculation } from "../sidebar/floor";
import { Download as FloorDownload } from "../sidebar/floor";
import { DownloadMail as FloorDownloadMail } from "../sidebar/floor";
import { DownloadSuccess as FloorDownloadSuccess } from "../sidebar/floor";

// SOL
import { ThermalCalculation as SolThermalCalculation } from "../sidebar/sol";
import { Download as SolDownload } from "../sidebar/sol";
import { DownloadMail as SolDownloadMail } from "../sidebar/sol";
import { DownloadSuccess as SolDownloadSuccess } from "../sidebar/sol";

const Container = styled.div`
  z-index: 1001;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;

  background-color: ${styles.colors.modal};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const map = new Map();
map.set(ModalDisplayTypes.SUPPORT, Support);
map.set(ModalDisplayTypes.SUPPORT_SUCCESS, SupportSuccess);
map.set(ModalDisplayTypes.COUNTRY_AND_LANGUAGE, CountryAndLanguage);
map.set(ModalDisplayTypes.CLIMATE_CLASS, ClimateClass);
map.set(ModalDisplayTypes.DETAIL_INFO, DetailInfo);
map.set(ModalDisplayTypes.REQUEST_ADVICE, RequestAdvice);
map.set(ModalDisplayTypes.REQUEST_ADVICE_SUCCESS, RequestAdviceSuccess);

// FLAT ROOF
map.set(ModalDisplayTypes.FLAT_ROOF_SPECIFICATIONS, FlatRoofThermalCalculation);
map.set(ModalDisplayTypes.DOWNLOAD_FLAT_ROOF, FlatRoofDownload);
map.set(ModalDisplayTypes.DOWNLOAD_MAIL_FLAT_ROOF, FlatRoofDownloadMail);
map.set(ModalDisplayTypes.DOWNLOAD_SUCCESS_FLAT_ROOF, FlatRoofDownloadSuccess);

// CAVITY WALL
map.set(ModalDisplayTypes.CAVITY_WALL_SPECIFICATIONS, CavityWallThermalCalculation);
map.set(ModalDisplayTypes.DOWNLOAD_CAVITY_WALL, CavityWallDownload);
map.set(ModalDisplayTypes.DOWNLOAD_MAIL_CAVITY_WALL, CavityWallDownloadMail);
map.set(ModalDisplayTypes.DOWNLOAD_SUCCESS_CAVITY_WALL, CavityWallDownloadSuccess);

// SLOPED ROOF
map.set(ModalDisplayTypes.SLOPED_ROOF_SPECIFICATIONS, SlopedRoofThermalCalculation);
map.set(ModalDisplayTypes.DOWNLOAD_SLOPED_ROOF, SlopedRoofDownload);
map.set(ModalDisplayTypes.DOWNLOAD_MAIL_SLOPED_ROOF, SlopedRoofDownloadMail);
map.set(ModalDisplayTypes.DOWNLOAD_SUCCESS_SLOPED_ROOF, SlopedRoofDownloadSuccess);

// VENTILATED WALL
map.set(ModalDisplayTypes.VENTILATED_WALL_SPECIFICATIONS, VentilatedWallThermalCalculation);
map.set(ModalDisplayTypes.VENTILATED_WALL_EXTENDED_SPECIFICATIONS, VentilatedWallExtendedThermalCalculation);
map.set(ModalDisplayTypes.DOWNLOAD_VENTILATED_WALL, VentilatedWallDownload);
map.set(ModalDisplayTypes.DOWNLOAD_MAIL_VENTILATED_WALL, VentilatedWallDownloadMail);
map.set(ModalDisplayTypes.DOWNLOAD_SUCCESS_VENTILATED_WALL, VentilatedWallDownloadSuccess);

// FLOOR
map.set(ModalDisplayTypes.FLOOR_SPECIFICATIONS, FloorThermalCalculation);
map.set(ModalDisplayTypes.DOWNLOAD_FLOOR, FloorDownload);
map.set(ModalDisplayTypes.DOWNLOAD_MAIL_FLOOR, FloorDownloadMail);
map.set(ModalDisplayTypes.DOWNLOAD_SUCCESS_FLOOR, FloorDownloadSuccess);

// SOL
map.set(ModalDisplayTypes.SOL_SPECIFICATIONS, SolThermalCalculation);
map.set(ModalDisplayTypes.DOWNLOAD_SOL, SolDownload);
map.set(ModalDisplayTypes.DOWNLOAD_MAIL_SOL, SolDownloadMail);
map.set(ModalDisplayTypes.DOWNLOAD_SUCCESS_SOL, SolDownloadSuccess);

export const Modal = () => {
  const { display, closeModal } = useModal();

  function handleClose() {
    closeModal();
  }

  const Component = map.get(display);

  if (display !== ModalDisplayTypes.NONE) {
    return (
      <Container onClick={handleClose}>
        <Component />
      </Container>
    );
  }

  return null;
};
