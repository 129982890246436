import axios from "axios"
import {
  VENTILATED_WALL_R_VALUE_RSI,
  EXTENDED_VENTILATED_WALL_R_VALUE_RSE,
} from "@iko-design-center/shared";
import React, { SyntheticEvent, useState } from "react";
import styled from "styled-components";
import { styles } from "../../../../styles";
import { OverviewItem, OverviewItemProps } from "../../../common/OverviewItem";
import { H1, H2, P, TypographyProps } from "../../../common/Typography";
import { UnstyledButton } from "../../../common/UnstyledButton";
import { Input } from "../../../common/Input";
import { CloseIcon, InfoIcon } from "../../../common/Icons";
import { useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../../store/modal/hooks";

import { PreliminaryTableBody } from "./PreliminaryTableBody";
import { PreliminaryOutcomeTableRow } from "./PreliminaryOutcomeTableRow";
import { CorrectionsTableBody } from "./CorrectionsTableBody";
import { CorrectionsOutcomeTableBody } from "./CorrectionsOutcomeTableBody";
import { TotalsOutcomeTableBody } from "./TotalsOutcomeTableBody";
import { Tooltip } from "../../../navigation/RegularTab";

const Container = styled.section`
  width: 90%;
  height: 90%;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;

  display: flex;
  flex-direction: column;

  sb {
    font-size: 62.5%;
    position: relative;
    top: 4px;
  }
`;

const SectionTop = styled.section`
  width: 100%;
  height: 100%;
  background: ${styles.colors.white};
  overflow-x: hidden;
  overflow-y: auto;
`;

const SectionBottom = styled.section`
`;

const SectionHeader = styled.div`
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Separator = styled.hr<{ margin?: string }>`
  border: none;
  background: #EBEBEB;
  height: ${(props) => props.margin ? '1px' : '2px'};
  margin: ${(props) => props.margin ?? 0};
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 16px 0 16px 32px;
  display: flex;
  align-items: center;
  background: #424754;

  * {
    color: ${styles.colors.white};
  }

  p { 
    line-height: 12px !important;
  }
`;

const TableBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    width: 100%;
  }
`;

const LeftColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: flex-start;
  align-items: flex-start;
  
  &.-corrections-column {
    flex: 0.3333333;
  }

  &.-corrections-left-column {
    flex: 0.6666;
    position: relative;

    select {
      width: 37.5%;
    }
  }
  &.-corrections-right-column {
    flex: 0.16666;
  }
`;

const RightColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.1666666;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled(UnstyledButton)`
  width: 13px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: #2E3341;
    }
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

const ActionsContainer = styled.div`
  width: 100%;
  background: ${styles.colors.darkBlue10Hex};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Action = styled(UnstyledButton) <{ primary?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  cursor: pointer;
  background-color: ${(props) => props.primary ? styles.colors.primary : styles.colors.darkBlue10Hex};
  color: ${(props) => props.primary ? styles.colors.white : styles.colors.dark2};
  font-size: ${14 / 16}rem;
  line-height: ${19 / 16}rem;
  font-weight: 500;

  &:hover {
    background-color: ${(props) => props.primary ? styles.colors.primaryNonHover : styles.colors.darkBlue30};
  }
`;

const h2Styles = {
  fontWeight: 500,
  fontSizePx: 14,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
} as TypographyProps;

const unitStyles = {
  fontSizePx: 12,
  lineHeightPx: 12,
  marginTop: '2px',
  color: `#B6B8C3 !important`,
} as TypographyProps;

export const ExtendedThermalCalculation = () => {
  const { t, i18n } = useTranslation() as any
  const { closeModal } = useModal()
  const language = i18n.language
  const { state, performance, corrections, constants } = useThermalState()

  const downloadExtendedThermalCalculation = () => {
    const baseUrl =
      process.env.NODE_ENV === "production" ? "" : "http://localhost:4000"
    const type = "ventilated-wall"

    axios
      .post<string>(
        `${baseUrl}/api/extended-thermal-calculation/download/${type}`,
        { language, state, performance, corrections, constants },
        { responseType: "blob" }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" })
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.setAttribute("href", url)
        link.setAttribute(
          "download",
          `${type}_extended-thermal-calculation.pdf`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((e: any) => console.error(e))

  }

  return (
    <Container>
      <SectionTop onClick={(e) => e.stopPropagation()}>
        <SectionHeader>
          <div>
            <H1 fontWeight={400} color="#6E7188" fontSizePx={17} lineHeightPx={20}>{t("extendedThermalCalculation.title")}</H1>
            <P fontWeight={400} color="#2E3341" fontSizePx={11} lineHeightPx={13}>{t("extendedThermalCalculation.subtitle")}</P>
          </div>
          <CloseButton>
            <CloseIcon onClick={closeModal} />
          </CloseButton>
        </SectionHeader>

        {/* Ut & Rt */}
        <TableBodyContainer>
          <div>
            <TableHeader>
              <LeftColumn>
                <H2 {...h2Styles} innerHTML={t("extendedThermalCalculation.preliminary.header")} />
              </LeftColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerThickness")}</H2>
                <P {...unitStyles}>{t("units.thicknessUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerLambdaValue")}</H2>
                <P {...unitStyles}>{t("units.lambdaUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerRValue")}</H2>
                <P {...unitStyles}>{t("units.insulationUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerUValue")}</H2>
                <P {...unitStyles}>{t("units.lambdaUnitBottom")}</P>
              </RightColumn>
            </TableHeader>
            <PreliminaryTableBody />
          </div>
        </TableBodyContainer>
        <Separator margin="0" />
        <TableBodyContainer>
          <PreliminaryOutcomeTableRow />
        </TableBodyContainer>

        <Separator />

        {/* dU */}
        <TableBodyContainer>
          <div>
            <TableHeader>
              <LeftColumn className="-corrections-column">
                <H2 {...h2Styles} innerHTML={t("extendedThermalCalculation.corrections.header")} />
              </LeftColumn>
              <RightColumn />
              <RightColumn />
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerLambdaValue")}</H2>
                <P {...unitStyles}>{t("units.lambdaUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerRValue")}</H2>
                <P {...unitStyles}>{t("units.insulationUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerUValue")}</H2>
                <P {...unitStyles}>{t("units.lambdaUnitBottom")}</P>
              </RightColumn>
            </TableHeader>
            <CorrectionsTableBody />
          </div>
        </TableBodyContainer>
        <Separator margin="0" />
        <TableBodyContainer>
          <CorrectionsOutcomeTableBody />
        </TableBodyContainer>
      </SectionTop>

      <SectionBottom onClick={(e) => e.stopPropagation()}>
        <BottomContainer>
          {/* Uc & Rc */}
          <TableBodyContainer>
            <TotalsOutcomeTableBody />
          </TableBodyContainer>

          {/* Actions */}
          <ActionsContainer>
            <Action onClick={closeModal}>
              {t("thermalCalculation.modalCancel")}
            </Action>
            <Action onClick={downloadExtendedThermalCalculation} primary>
              {t("extendedThermalCalculation.totals.download")}
            </Action>
          </ActionsContainer>
        </BottomContainer>
      </SectionBottom>
    </Container>
  );
};

const TableRowContainer = styled.div`
  padding: 16px 0 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.-even-row {
    background: ${styles.colors.darkBlue5};
  }

  &.-corrections {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 58.15%;
      width: 1px;
      height: 100%;
      background-color: ${styles.colors.darkBlue10};
    }
  }
`;

const TableRowLeftColumn = styled(LeftColumn)`
  display: flex;
`;

const TableRowRightColumn = styled(RightColumn) <{ input?: Boolean }>`
  display: flex;
  align-self: ${(props) => (props.input ? "flex-end" : "center")};
`;

export const TableSubtitleRow = styled.div`
  display: flex;
  width: 100%;
  background: ${styles.colors.darkBlue10};
  padding: 8px 32px;
  font-weight: 500;
  font-size: ${14 / 16} rem;
  line-height: 19px;
  color: ${styles.colors.dark2};
`;

const CustomInput = styled(Input)`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: ${16 / 16} rem;
  line-height: ${18 / 16} rem;
  color: ${styles.colors.dark2};
  letter-spacing: 0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80px;
  border: 1px solid ${styles.colors.darkBlue30};
  padding: 6px 12px 5px 12px;
  border-radius: 4px;
  margin-top: 0;
`;

const TooltipLeft = styled(Tooltip)`
  left: 15%;
  transform: translate(-15%, 10px);
  min-width: 300px;
    
  &.-open {
    opacity: 1;
    transform: translate(-15%, 0);
  }

  &::before {
    transform: translateX(-15%) rotate(45deg);
    left: 15%;
  }
`;

const TooltipContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const valueStyle = {
  fontSizePx: 14,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
  marginTop: '0'
} as TypographyProps;

type TableRowProps = {
  type: string;
  thickness?: number | string;
  lambdaValue?: number | string;
  insulationValue?: number | string;
  UValue?: number | string;
  editableThickness?: Boolean;
  onEditableThicknessChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  editableThicknessValue?: string;
  editableThicknessValid?: boolean;
  options?: Array<{ label: string; value: string }>;
  onOptionChange?: (event: SyntheticEvent<HTMLSelectElement>) => void;
  classes?: string;
} & OverviewItemProps;

export const TableRow = ({
  type,
  title,
  text,
  suffix,
  thickness,
  insulationValue,
  UValue,
  lambdaValue,
  editableThickness,
  onEditableThicknessChange,
  editableThicknessValue,
  editableThicknessValid,
  options,
  onOptionChange,
  classes,
}: TableRowProps) => {
  return (
    <TableRowContainer className={classes}>
      <TableRowLeftColumn>
        <OverviewItem title={title} text={text} suffix={suffix} options={options} optionType={type} onOptionChange={onOptionChange} />
      </TableRowLeftColumn>

      <TableRowRightColumn input={editableThickness}>
        {editableThickness && (
          <CustomInput
            data-type={type}
            invalid={!editableThicknessValid}
            value={editableThicknessValue ?? thickness}
            onChange={onEditableThicknessChange}
          />
        )}
        {!editableThickness && <P {...valueStyle}>{thickness ?? '—'}</P>}
      </TableRowRightColumn>

      <TableRowRightColumn>
        <P {...valueStyle}>{lambdaValue ?? '—'}</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P {...valueStyle}>{insulationValue ?? '—'}</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P {...valueStyle}>{UValue ?? '—'}</P>
      </TableRowRightColumn>

    </TableRowContainer>
  );
};

export const RSRow = ({ type }: { type: "RSI" | "RSE" }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { t } = useTranslation() as any;

  return (
    <TableRowContainer>
      <TableRowLeftColumn>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <P
            fontWeight={500}
            fontSizePx={14}
            lineHeightPx={19}
            color={styles.colors.dark2}
            marginTop="0"
          >
            {type}
          </P>
          <TooltipContainer onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
            <InfoIcon />

            <TooltipLeft className={`tooltip ${tooltipOpen ? "-open" : ""} `}>
              <div className="tooltip-content">
                {type === "RSI" && (<p dangerouslySetInnerHTML={{ __html: t("extendedThermalCalculation.preliminary.RSIInfo") }}></p>)}
                {type === "RSE" && (<p dangerouslySetInnerHTML={{ __html: t("extendedThermalCalculation.preliminary.RSEInfo") }}></p>)}
              </div>
            </TooltipLeft>
          </TooltipContainer>
        </div>
      </TableRowLeftColumn>

      <TableRowRightColumn>
        <P fontWeight={400} fontSizePx={14} lineHeightPx={19} color={styles.colors.dark2} marginTop="0">—</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P fontWeight={400} fontSizePx={14} lineHeightPx={19} color={styles.colors.dark2} marginTop="0">—</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P {...valueStyle}>
          {type === "RSI"
            ? Number(VENTILATED_WALL_R_VALUE_RSI).toFixed(3)
            : Number(EXTENDED_VENTILATED_WALL_R_VALUE_RSE).toFixed(3)
          }
        </P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P fontWeight={400} fontSizePx={14} lineHeightPx={19} color={styles.colors.dark2} marginTop="0">—</P>
      </TableRowRightColumn>
    </TableRowContainer >
  );
};

const CorrectionPropertyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 28px;
  column-gap: 64px;
  row-gap: 12px;
  width: calc(calc(37.5% * 2) + 64px);
`;

const CorrectionProperty = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 38px;
  min-width: 37.5%;

  p {
    font-size: ${14 / 16} rem;
    line-height: ${19 / 19} rem;
    font-weight: 700;
    color: ${styles.colors.darkBlue};
  }
`;

type TableCorrectionsRowProps = {
  type: string;
  count?: number | string;
  diameter?: number | string;
  lambdaValue?: number | string;
  insulationValue?: number | string;
  UValue?: number | string;
  editableFixationCount?: Boolean;
  onEditableFixationCountChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  editableFixationDiameter?: Boolean;
  onEditableFixationDiameterChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  options?: Array<{ label: string; value: string }>;
  onOptionChange?: (event: SyntheticEvent<HTMLSelectElement>) => void;
  classes?: string;
} & OverviewItemProps;

export const TableCorrectionsRow = ({
  type,
  title,
  text,
  suffix,
  insulationValue,
  UValue,
  count,
  diameter,
  lambdaValue,
  editableFixationCount,
  onEditableFixationCountChange,
  editableFixationDiameter,
  onEditableFixationDiameterChange,
  options,
  onOptionChange,
  classes,
}: TableCorrectionsRowProps) => {
  const { t } = useTranslation() as any;
  return (
    <TableRowContainer className={classes} >
      <TableRowLeftColumn className="-corrections-left-column">
        <OverviewItem title={title} text={text} suffix={suffix} options={options} optionType={type} onOptionChange={onOptionChange} />
        {(editableFixationCount || editableFixationDiameter) && (
          <CorrectionPropertyGrid>
            {/* Diameter */}
            {editableFixationDiameter && (
              <CorrectionProperty>
                <P {...valueStyle}>{t("extendedThermalCalculation.correction.fixationDiameterLabel")}</P>
                <CustomInput data-type={type} invalid={false} value={diameter} onChange={onEditableFixationDiameterChange} />
              </CorrectionProperty>
            )}

            <CorrectionProperty />

            {/* Count */}
            {editableFixationCount && (
              <CorrectionProperty>
                <P {...valueStyle}>{t("extendedThermalCalculation.correction.fixationCountLabel")}</P>
                <CustomInput data-type={type} invalid={false} value={count} onChange={onEditableFixationCountChange} />
              </CorrectionProperty>
            )}

            <CorrectionProperty />
          </CorrectionPropertyGrid>
        )}
      </TableRowLeftColumn>

      <TableRowRightColumn className="-corrections-right-column">
        <P {...valueStyle}>{lambdaValue ?? '—'}</P>
      </TableRowRightColumn>
      <TableRowRightColumn className="-corrections-right-column">
        <P {...valueStyle}>{insulationValue ?? '—'}</P>
      </TableRowRightColumn>
      <TableRowRightColumn className="-corrections-right-column">
        <P {...valueStyle}>{UValue ?? '—'}</P>
      </TableRowRightColumn>

    </TableRowContainer >
  );
};