import {
    VENTILATED_WALL_R_VALUE_RSI,
    EXTENDED_VENTILATED_WALL_R_VALUE_RSE,
} from "@iko-design-center/shared";
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useVentilatedWall } from "../../../../store/ventilated-wall/hooks";
import { useExtendedThermalCalculation } from "./ventilatedWallExtendedCalculation.hook";
import { parseMaterial } from "../../../../services/helpers.service";
import { ExtendedVentilatedWallWallTypeTypes, VentilatedWallFacadeCladdingTypes, VentilatedWallVaporShieldTypes, VentilatedWallWallTypeTypes, VentilationTypes } from "@iko-design-center/shared/lib/constants/constants";
import { getWallTypeTranslations } from "../translations";
import { useTranslation } from "react-i18next";

type State = any
type ThermalStateProviderProps = { children: ReactNode }
const ThermalStateContext = createContext<State | undefined>(undefined)

function ThermalStateProvider({ children }: ThermalStateProviderProps) {
    const { t } = useTranslation() as any
    const { configurationState } = useVentilatedWall()
    const {
        calculateMaterialThermalPerformance,
        calculatePreliminaryThermalPerformance,
        calculateConvectionCorrection,
        calculateFixationCorrection,
        calculateCorrection,
        calculateTotalsThermalPerformance,
    } = useExtendedThermalCalculation()

    const initialState: any = {
        wallType: null,
        wallInsulation: {
            type: configurationState.wallInsulation.type,
            material: configurationState.wallInsulation.material,
        },
        wallCavity: {
            type: VentilationTypes.STRONG_VENTILATION,
            material: parseMaterial('STRONG_VENTILATION'),
        },
        wallVaporShield: {
            type: VentilatedWallVaporShieldTypes.WATERPROOF_VAPOR_SHIELD,
            material: parseMaterial('WATERPROOF_VAPOR_SHIELD'),
        },
        wallFacadeCladding: {
            type: VentilatedWallFacadeCladdingTypes.FIBER_CEMENT_CLADDING,
            material: parseMaterial('FIBER_CEMENT_CLADDING'),
        },
    }

    switch (configurationState.wallType.type) {
        case VentilatedWallWallTypeTypes.CONCRETE_WALL:
            initialState.wallType = {
                type: ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_2400, material: parseMaterial('EXT_CONCRETE_WALL_2400'), options:
                    [
                        { value: "EXT_CONCRETE_WALL_2400", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_2400) },
                        { value: "EXT_CONCRETE_WALL_400", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_400) },
                        { value: "EXT_CONCRETE_WALL_500", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_500) },
                        { value: "EXT_CONCRETE_WALL_600", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_600) },
                        { value: "EXT_CONCRETE_WALL_700", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_700) },
                        { value: "EXT_CONCRETE_WALL_800", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_800) },
                    ]
            }
            break
        case VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL:
            initialState.wallType = {
                type: ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_BRICKED, material: parseMaterial('EXT_HOLLOW_STONE_WALL_1400_BRICKED'), options:
                    [
                        { value: "EXT_HOLLOW_STONE_WALL_1100_GLUED", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_GLUED) },
                        { value: "EXT_HOLLOW_STONE_WALL_1100_BRICKED", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_BRICKED) },
                        { value: "EXT_HOLLOW_STONE_WALL_1400_GLUED", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_GLUED) },
                        { value: "EXT_HOLLOW_STONE_WALL_1400_BRICKED", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_BRICKED) },
                    ]
            }
            break
        case VentilatedWallWallTypeTypes.LIME_SAND_WALL:
            initialState.wallType = {
                type: ExtendedVentilatedWallWallTypeTypes.EXT_LIME_SAND_WALL_1850, material: parseMaterial('EXT_LIME_SAND_WALL_1850'), option:
                    [
                        { value: "EXT_LIME_SAND_WALL_1850", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_LIME_SAND_WALL_1850) },
                    ]
            }
            break
        case VentilatedWallWallTypeTypes.CROSSWOOD_WALL:
            initialState.wallType = {
                type: ExtendedVentilatedWallWallTypeTypes.EXT_CROSSWOOD_WALL, material: parseMaterial('EXT_CROSSWOOD_WALL'), option:
                    [
                        { value: "EXT_CROSSWOOD_WALL", label: getWallTypeTranslations(t, ExtendedVentilatedWallWallTypeTypes.EXT_CROSSWOOD_WALL) },
                    ]
            }
            break
    }

    const initialPerformance = {
        wallType: calculateMaterialThermalPerformance(initialState.wallType.material),
        wallInsulation: calculateMaterialThermalPerformance(initialState.wallInsulation.material),
        wallCavity: calculateMaterialThermalPerformance(initialState.wallCavity.material),
        wallVaporShield: calculateMaterialThermalPerformance(initialState.wallVaporShield.material),
        wallFacadeCladding: calculateMaterialThermalPerformance(initialState.wallFacadeCladding.material),
        UTValue: null as number | null,
        RTValue: null as number | null,
        convectionUValue: null as number | null,
        fixationUValue: null as number | null,
        deltaUValue: null as number | null,
        UCValue: null as number | null,
        RCValue: null as number | null,
    }

    const initialCorrections = {
        convection: {
            type: "NO_CONVECTION",
            UValue: 0,
        },
        fixation: {
            type: "GALVANIZED_STEEL_FIXATION",
            lambda: 50,
            diameter: 6,
            count: 3
        },
    }

    const initialConstants = {
        RSE: { label: 'RSE', RValue: EXTENDED_VENTILATED_WALL_R_VALUE_RSE },
        RSI: { label: 'RSI', RValue: VENTILATED_WALL_R_VALUE_RSI },
    }

    const [state, setState] = useState(initialState)
    const [performance, setPerformance] = useState(initialPerformance)
    const [corrections, setCorrections] = useState(initialCorrections)
    const [constants, setConstants] = useState(initialConstants)

    useEffect(() => {
        // Preliminary
        const wallTypeThermalPerformance = calculateMaterialThermalPerformance(state.wallType.material)
        const wallInsulationThermalPerformance = calculateMaterialThermalPerformance(state.wallInsulation.material)
        const wallCavityThermalPerformance = calculateMaterialThermalPerformance(state.wallCavity.material)
        const wallVaporShieldThermalPerformance = calculateMaterialThermalPerformance(state.wallVaporShield.material)
        const wallFacadeCladdingThermalPerformance = calculateMaterialThermalPerformance(state.wallFacadeCladding.material)
        const [RTValue, UTValue] = calculatePreliminaryThermalPerformance(
            [
                wallTypeThermalPerformance,
                wallInsulationThermalPerformance,
            ],
            VENTILATED_WALL_R_VALUE_RSI,
            EXTENDED_VENTILATED_WALL_R_VALUE_RSE
        )
        // Corrections
        const convectionUValue = calculateConvectionCorrection({
            convectionU: corrections.convection.UValue,
            insulationR: wallInsulationThermalPerformance.RValue,
            RT: RTValue,
        })
        const fixationUValue = calculateFixationCorrection({
            fixationLambda: corrections.fixation.lambda,
            fixationDiameter: corrections.fixation.diameter,
            fixationCount: corrections.fixation.count,
            insulationD: wallInsulationThermalPerformance.thickness,
            insulationR: wallInsulationThermalPerformance.RValue,
            RT: RTValue,
        })
        const deltaUValue = calculateCorrection(convectionUValue, fixationUValue, UTValue)
        // Totals
        const [RCValue, UCValue] = calculateTotalsThermalPerformance(
            UTValue,
            deltaUValue,
            VENTILATED_WALL_R_VALUE_RSI,
            EXTENDED_VENTILATED_WALL_R_VALUE_RSE
        )

        const newThermalPerformance = {
            ...performance,
            wallType: wallTypeThermalPerformance,
            wallInsulation: wallInsulationThermalPerformance,
            wallCavity: wallCavityThermalPerformance,
            wallVaporShield: wallVaporShieldThermalPerformance,
            wallFacadeCladding: wallFacadeCladdingThermalPerformance,
            RTValue,
            UTValue,
            convectionUValue,
            fixationUValue,
            deltaUValue,
            UCValue,
            RCValue
        }

        setPerformance(newThermalPerformance)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, corrections])

    return (
        <ThermalStateContext.Provider value={{
            state, setState,
            performance, setPerformance,
            corrections, setCorrections,
            constants, setConstants
        }}>
            {children}
        </ThermalStateContext.Provider>
    )
}

function useThermalState() {
    const context = useContext(ThermalStateContext)
    if (context === undefined) {
        throw new Error('useThermalState must be used within a ThermalStateProvider')
    }
    return context
}

export { ThermalStateProvider, useThermalState }
