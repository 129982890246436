import styled from "styled-components";
import { RSRow, Separator, TableRow, TableSubtitleRow } from "./ExtendedThermalCalculation";
import { getWallInsulationTranslations, getWallTypeTranslations, getWallCavityTranslations, getFacadeCladdingTranslations, getWallVaporShieldTranslations } from "../translations";
import { useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useMemo } from "react";
import { getEnumValue, parseMaterial } from "../../../../services/helpers.service";
import { VentilatedWallFacadeCladdingTypes } from "@iko-design-center/shared";
import { ExtendedVentilatedWallWallTypeTypes, VentilatedWallVaporShieldTypes } from "@iko-design-center/shared/lib/constants/constants";
import { useExtendedThermalCalculation } from "./ventilatedWallExtendedCalculation.hook";

const TableBody = styled.div`
`;

export const PreliminaryTableBody = () => {
    const { t } = useTranslation() as any;
    const { state, setState, performance } = useThermalState();
    const { formatCalc } = useExtendedThermalCalculation();

    const wallVaporShieldOptions = useMemo(() => {
        return [
            { value: state.wallVaporShield.material.id, label: getWallVaporShieldTranslations(t, state.wallVaporShield.type) },
            { value: "CEMENT_WINDBREAKER", label: getWallVaporShieldTranslations(t, VentilatedWallVaporShieldTypes.CEMENT_WINDBREAKER) },
            { value: "WATERPROOF_VAPOR_SHIELD", label: getWallVaporShieldTranslations(t, VentilatedWallVaporShieldTypes.WATERPROOF_VAPOR_SHIELD) },
            { value: "NO_WATERPROOF_VAPOR_SHIELD", label: getWallVaporShieldTranslations(t, VentilatedWallVaporShieldTypes.NO_WATERPROOF_VAPOR_SHIELD) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.wallVaporShield, t]);

    const wallFacadeCladdingOptions = useMemo(() => {
        return [
            { value: state.wallFacadeCladding.material.id, label: getFacadeCladdingTranslations(t, state.wallFacadeCladding.type) },
            { value: "FIBER_CEMENT_CLADDING", label: getFacadeCladdingTranslations(t, VentilatedWallFacadeCladdingTypes.FIBER_CEMENT_CLADDING) },
            { value: "WOOD_CLADDING", label: getFacadeCladdingTranslations(t, VentilatedWallFacadeCladdingTypes.WOOD_CLADDING) },
            { value: "METAL_CLADDING", label: getFacadeCladdingTranslations(t, VentilatedWallFacadeCladdingTypes.METAL_CLADDING) },
            { value: "COMPOSITE_CLADDING", label: getFacadeCladdingTranslations(t, VentilatedWallFacadeCladdingTypes.COMPOSITE_CLADDING) },
            { value: "OTHER_CLADDING", label: getFacadeCladdingTranslations(t, VentilatedWallFacadeCladdingTypes.OTHER_CLADDING) },
        ].filter((x, index, array) => index === array.findIndex((t) => t.value === x.value))
        // .sort((a, b) => a.label.localeCompare(b.label));
    }, [state.wallFacadeCladding, t]);

    const handleEditableThicknessChange = (event: SyntheticEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        const type = input.dataset.type!;
        let thickness = isNaN(parseInt(input.value)) ? 0 : parseInt(input.value);

        let validThickness = true;
        if (!thickness) validThickness = false;

        if (type) {
            setState({
                ...state,
                [type]: {
                    ...state[type],
                    valid: validThickness,
                    material: { ...state[type].material, thickness },
                },
            });
        }
    }

    const handleEditableOptionChange = (event: SyntheticEvent<HTMLSelectElement>, EnumType: any) => {
        const input = event.target as HTMLInputElement
        const type = input.dataset.type!
        const material = parseMaterial(input.value)

        if (material) {
            setState({
                ...state,
                [type]: { ...state[type], type: getEnumValue(input.value, EnumType), material },
            });
        }
    }

    return (
        <TableBody>
            <RSRow type={"RSI"} />

            <TableSubtitleRow>{t("extendedThermalCalculation.preliminary.subtitle")}</TableSubtitleRow>
            <Separator />

            {/* Wall type */}
            <TableRow
                type="wallType"
                title={t("ventilatedWall.overview.wallType").toUpperCase()}
                text={getWallTypeTranslations(t, state.wallType.type)}
                thickness={performance.wallType.thickness!}
                lambdaValue={formatCalc(performance.wallType.lambda!)}
                insulationValue={formatCalc(performance.wallType.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.wallType.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.wallType.valid}

                options={state.wallType.options}
                onOptionChange={(event) => handleEditableOptionChange(event, ExtendedVentilatedWallWallTypeTypes)}
            />

            {/* Wall insulation */}
            <Separator margin="0" />
            <TableRow
                classes="-even-row"
                type="wallInsulation"
                title={t("ventilatedWall.overview.wallInsulation").toUpperCase()}
                text={getWallInsulationTranslations(t, state.wallInsulation.type)}
                thickness={performance.wallInsulation.thickness!}
                lambdaValue={formatCalc(performance.wallInsulation.lambda!)}
                insulationValue={formatCalc(performance.wallInsulation.RValue!)}

                editableThickness={true}
                editableThicknessValue={performance.wallInsulation.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.wallInsulation.valid}
            />

            {/* Wall cavity */}
            <Separator margin="0" />
            <TableRow
                type="wallCavity"
                title={t("cavityWall.overview.airCavity").toUpperCase()}
                text={getWallCavityTranslations(t, state.wallCavity.type)}
                thickness={performance.wallCavity.thickness!}
                lambdaValue={undefined}
                insulationValue={formatCalc(undefined)}

                editableThickness={true}
                editableThicknessValue={performance.wallCavity.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.wallCavity.valid}
            />

            {/* Wall vapor shield */}
            <Separator margin="0" />    
            <TableRow
                classes="-even-row"
                type="wallVaporShield"
                title={t("flatRoof.securingMethods.vaporShield").toUpperCase()}
                text={getWallVaporShieldTranslations(t, state.wallVaporShield.type)}
                thickness={undefined}
                lambdaValue={undefined}
                insulationValue={formatCalc(undefined)}

                options={wallVaporShieldOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, VentilatedWallVaporShieldTypes)}
            />

            {/* Wall facade cladding */}
            <Separator margin="0" />
            <TableRow
                type="wallFacadeCladding"
                title={t("ventilatedWall.overview.facadeCladding").toUpperCase()}
                text={getFacadeCladdingTranslations(t, state.wallFacadeCladding.type)}
                thickness={performance.wallFacadeCladding.thickness!}
                lambdaValue={undefined}
                insulationValue={formatCalc(undefined)}

                editableThickness={true}
                editableThicknessValue={performance.wallFacadeCladding.thickness!}
                onEditableThicknessChange={handleEditableThicknessChange}
                editableThicknessValid={performance.wallFacadeCladding.valid}

                options={wallFacadeCladdingOptions}
                onOptionChange={(event) => handleEditableOptionChange(event, VentilatedWallFacadeCladdingTypes)}
            />

            <Separator margin="0" />

            <RSRow type={"RSE"} />
        </TableBody>
    )
}

